var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hello"},[_c('h1',[_vm._v(_vm._s(_vm.msg))]),_c('h3',[_vm._v("KAFKA")]),_vm._m(0),_c('h3',[_vm._v("Redis")]),_vm._m(1),_c('h3',[_vm._v("SpringJpa")]),_vm._m(2),_c('h3',[_vm._v("PythonFastAPI")]),_vm._m(3),_c('h3',[_vm._v("Subtitle Factory Server")]),_vm._m(4),_c('h3',[_vm._v("Subtitle Factory Front")]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.lidy.synology.me/kafka/swagger-ui/index.html","target":"_blank","rel":"noopener"}},[_vm._v("KAFKA")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.lidy.synology.me/redis/swagger-ui/index.html","target":"_blank","rel":"noopener"}},[_vm._v("Redis")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.lidy.synology.me/springjpa/swagger-ui/index.html","target":"_blank","rel":"noopener"}},[_vm._v("SpringJpa")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://fast.lidy.synology.me/docs","target":"_blank","rel":"noopener"}},[_vm._v("PythonFastAPI")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://www.lidy.synology.me/subfacserver/swagger-ui/index.html","target":"_blank","rel":"noopener"}},[_vm._v("SubFac Server")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"https://subfac.lidy.synology.me/","target":"_blank","rel":"noopener"}},[_vm._v("SubFac Front")])])])
}]

export { render, staticRenderFns }